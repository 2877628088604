import React from 'react';
import MetaTags from 'react-meta-tags';
import { Layout } from 'antd';
import '../styles/partners.css';
import Banner from "../components/Banner";
import aupostImg from '../img/ship01_Aupost.jpg';
import colissimoImg from '../img/ship02_colissimo.jpg';
import correosImg from '../img/ship03_correos.jpg';
import deutscheImg from '../img/ship04_deutsche-post.jpg';
import dhlImg from '../img/ship05_DHL-C.jpg';
import dpdImg from '../img/ship06_dpd.jpg';
import fastwayImg from '../img/ship07_fastway.jpg';
import fedexImg from '../img/ship08_fedex-C.jpg';
import hermesImg from '../img/ship09_hermes-C.jpg';
import royalMailImg from '../img/ship10_royal-mail-C.jpg';
import tntImg from '../img/ship11_TNT.jpg';
import upsImg from '../img/ship12_UPS-C.jpg';
import uspsImg from '../img/ship13_USPS.jpg';
import amazonImg from '../img/buy01_amazon-C.jpg';
import cDiscountImg from '../img/buy02_Cdiscount.jpg';
import ebayImg from '../img/buy03_ebay-C.jpg';
import facebookImg from '../img/buy04_facebook.jpg';
import indiegogoImg from '../img/buy05_indiegogo.jpg';
import kicksrarterImg from '../img/buy06_kicksrarter.jpg';
import newEggImg from '../img/buy07_new-egg.jpg';
import rakutenImg from '../img/buy08_rakuten.jpg';
import walmartImg from '../img/buy09_walmart.jpg';
import wishImg from '../img/buy10_wish.jpg';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

function Partners(props) {
  const { t } = useTranslation();

  return(
    <>
      <MetaTags>
        <title>合作夥伴 | CBES 唯高盛集團</title>
      </MetaTags>
      <Banner data={ props.data } />
      <Content>
        <div className="wrap">
          <div className="partners_content">
            <h3>
              { t('partners.title.courier') }
            </h3>
            <div className="divider">
              <div className="divider_deco"></div>
            </div>
            <div className="ship_list">
              <div data-aos="fade-up" className="item">
                <a href="https://auspost.com.au/" target="_blank" rel="noreferrer noopener">
                  <img src={ aupostImg } alt="auspost_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="150" className="item">
                <a href="https://www.laposte.fr/colissimo" target="_blank" rel="noreferrer noopener">
                  <img src={ colissimoImg } alt="colissimo_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="300" className="item">
                <a href="https://www.correos.es/ss/Satellite/site/pagina-inicio/info" target="_blank" rel="noreferrer noopener">
                  <img src={ correosImg } alt="correos_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="450" className="item">
                <a href="https://www.deutschepost.de/de.html" target="_blank" rel="noreferrer noopener">
                  <img src={ deutscheImg } alt="deutsche_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="600" className="item">
                <a href="http://www.dhl.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ dhlImg } alt="dhl_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="750" className="item">
                <a href="https://www.dpd.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ dpdImg } alt="dpd_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="900" className="item">
                <a href="https://www.fastway.com.au/" target="_blank" rel="noreferrer noopener">
                  <img src={ fastwayImg } alt="fastway_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="1050" className="item">
                <a href="https://www.fedex.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ fedexImg } alt="fedex_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="1200" className="item">
                <a href="https://www.hermesworld.com/en/" target="_blank" rel="noreferrer noopener">
                  <img src={ hermesImg } alt="hermes_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="1350" className="item">
                <a href="https://www.royalmail.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ royalMailImg } alt="royalMail_logo" />
                </a>
              </div>
            </div>
            <h3>
              { t('partners.title.marketplace') }
            </h3>
            <div className="divider">
              <div className="divider_deco"></div>
            </div>
            <div className="buy_list">
              <div data-aos="fade-up" data-aos-delay="300" className="item">
                <a href="https://www.ebay.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ ebayImg } alt="ebay_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="450" className="item">
                <a href="https://www.facebook.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ facebookImg } alt="facebook_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="600" className="item">
                <a href="https://www.indiegogo.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ indiegogoImg } alt="indiegogo_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="750" className="item">
                <a href="https://www.kickstarter.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ kicksrarterImg } alt="kicksrarter_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="900" className="item">
                <a href="https://www.newegg.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ newEggImg } alt="newEgg_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="1050" className="item">
                <a href="https://www.rakuten.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ rakutenImg } alt="rakuten_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="1200" className="item">
                <a href="https://www.walmart.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ walmartImg } alt="walmart_logo" />
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="1350" className="item">
                <a href="https://www.wish.com/" target="_blank" rel="noreferrer noopener">
                  <img src={ wishImg } alt="wish_logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </>
  )
}

export default Partners;
