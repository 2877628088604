import React from 'react';
import MetaTags from 'react-meta-tags';
import { Layout } from 'antd';
import '../styles/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import bannerImg_zh_1 from '../img/BANNER3-ZH.jpg';
import bannerImg_zh_2 from '../img/BANNER2-ZH.jpg';
import bannerImg_cn_1 from '../img/BANNER3－CHS.jpg';
import bannerImg_cn_2 from '../img/BANNER2－CHS.jpg';
import bannerImg_en_1 from '../img/BANNER3-EN.jpg';
import bannerImg_en_2 from '../img/BANNER2-EN.jpg';
import box_01 from '../img/box01.jpg';
import box_02 from '../img/box02.jpg';
import box_03 from '../img/box03.jpg';
import box_04 from '../img/box04.jpg';
import news_BG from '../img/NEWS-BG.jpg';
import Buyippee from '../img/buyippee.png';
import Continental from '../img/Continental.png';
import Ids from '../img/IDS.png';
import banner_about from '../img/banner_about.jpg';
import banner_service from '../img/banner_service.jpg';
import { ArrowRightOutlined } from "@ant-design/icons";
import PartnerImages from '../components/PartnerComp';
import { useTranslation } from 'react-i18next';
import newsList from '../config/newsList';
import blogList from '../config/blogList';

export default function Index() {
  const { Content } = Layout;
  const settings = {
    autoplay: true,
    swipe: false,
  };
  const partner_settings = {
    autoplay: true,
    arrows: false,
    swipe: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  // Change banner image according to current language
  const { t, i18n } = useTranslation();
  let lang = 'zh-hant';
  let bannerImgEl_1;
  let bannerImgEl_2;
  if(i18n.language === 'zh-TW'){
    lang = 'zh-hant';
    bannerImgEl_1 = 
    <div className='image' style={{backgroundImage: `url(${bannerImg_zh_1})`}}></div>;
    bannerImgEl_2 =
    <div className='image' style={{backgroundImage: `url(${bannerImg_zh_2})`}}></div>;
  } else if(i18n.language === 'zh-CN'){
    lang = 'zh-hans';
    bannerImgEl_1 = 
    <div className='image' style={{backgroundImage: `url(${bannerImg_cn_1})`}}></div>;
    bannerImgEl_2 =
    <div className='image' style={{backgroundImage: `url(${bannerImg_cn_2})`}}></div>;
  } else if (i18n.language === 'en'){
    lang = 'en';
    bannerImgEl_1 = 
    <div className='image' style={{backgroundImage: `url(${bannerImg_en_1})`}}></div>;
    bannerImgEl_2 =
    <div className='image' style={{backgroundImage: `url(${bannerImg_en_2})`}}></div>;
  } 

  return (
    <>
      <MetaTags>
        <title>CBES 唯高盛集團 一站式跨境電商服務</title>
      </MetaTags>
      <Content className="index_content">
        <div className='page-wrapper'>
          <div className='core-wrapper' style={{padding: 0}}>
            <div className='bg-wrapper'></div>
            <div className='container-wrapper'>
              <div className='slider-wrapper'>
                <Slider {...settings}>
                  <div className='image-wrapper'>
                    { bannerImgEl_1 }
                  </div>
                  <div className='image-wrapper'>
                    { bannerImgEl_2 }
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className='core-wrapper four-item' style={{margin: 0, padding: '0px 0px 60px 0px'}}>
            <div className='bg-wrapper' style={{backgroundColor: '#ffffff'}}>
              <div className='bg'
                   style={{
                     backgroundRepeat: 'repeat',
                     backgroundPosition: 'center center',
                     height: '220.4px',
                     transform: 'translate(0px, -17.2px)'
                   }}></div>
            </div>
            <div className='container-wrapper clearfix container'>
              <div className='column column-15' style={{clear: 'left'}}>
                <div
                  style={{position: 'relative', margin: '-100px 0px 0px', padding: '75px 0px 65px'}}>
                  <div className='bg-wrapper'>
                    <div className='bg' style={{
                      backgroundImage: `url(${box_01})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}></div>
                  </div>
                  <div className='title-item center-align'>
                    <h6>
                      { t('home.box1.title') }
                    </h6>
                    <span>
                      { t('home.box1.subtitle') }
                    </span>
                  </div>
                </div>
              </div>
              <div className='column column-15'>
                <div
                  style={{position: 'relative', margin: '-100px 0px 0px', padding: '75px 0px 65px'}}>
                  <div className='bg-wrapper' style={{backgroundColor: '#000000'}}>
                    <div className='bg' style={{
                      backgroundImage: `url(${box_02})`,
                      opacity: 0.5,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}></div>
                  </div>
                  <div className='title-item center-align'>
                    <h6>
                      { t('home.box2.title') }
                    </h6>
                    <span>
                      { t('home.box2.subtitle') }
                    </span>
                  </div>
                </div>
              </div>
              <div className='column column-15'>
                <div
                  style={{position: 'relative', margin: '-100px 0px 0px', padding: '75px 0px 65px'}}>
                  <div className='bg-wrapper' style={{backgroundColor: '#ffffff'}}>
                    <div className='bg' style={{
                      backgroundImage: `url(${box_03})`,
                      opacity: 0.9,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}></div>
                  </div>
                  <div className='title-item center-align'>
                    <h6>
                      { t('home.box3.title') }
                    </h6>
                    <span>
                      { t('home.box3.subtitle') }
                    </span>
                  </div>
                </div>
              </div>
              <div className='column column-15'>
                <div
                  style={{position: 'relative', margin: '-100px 0px 0px', padding: '75px 0px 65px'}}>
                  <div className='bg-wrapper' style={{backgroundColor: '#ffffff'}}>
                    <div className='bg' style={{
                      backgroundImage: `url(${box_04})`,
                      opacity: 0.9,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}></div>
                  </div>
                  <div className='title-item center-align'>
                    <h6>
                      { t('home.box4.title') }
                    </h6>
                    <span>
                      { t('home.box4.subtitle') }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='core-wrapper about-intro'>
            <div className='container-wrapper container'>
              <div className='column column-20' style={{clear: 'left'}}>
                <div style={{margin: '0px 0px 0px -15px', padding: 0}}>
                  <div className='column-content'>
                    <div className='title-item clearfix left-align' style={{paddingBottom: '15px'}}>
                      <h3>
                        { t('home.intro.title') }
                      </h3>
                    </div>
                    <div className='divider-item left-align' style={{paddingLeft: '15px', paddingRight: '15px'}}>
                      <div className='divider-container'>
                        <div style={{
                          borderColor: '#00baed',
                          borderBottomWidth: '2px',
                          borderBottomStyle: 'solid'
                        }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='column column-40'>
                <div style={{position: 'relative', padding: 0}}>
                  <div className='column-content clearfix'>
                    <div className='core-item-pd-lr left-align' style={{paddingBottom: '15px'}}>
                      <div style={{fontSize: '16px', textTransform: 'none', color: '#727272', lineHeight: 1.7}}>
                        <p style={{paddingBottom: '20px'}}>
                          { t('home.intro.content') }
                        </p>
                      </div>
                    </div>
                    <div className='core-item-pd-lr core-item-pd-b left-align'>
                      <Link 
                        style={{
                           fontSize: '16px',
                           color: '#1ebef0',
                           padding: '15px 30px 15px 30px',
                           borderRadius: '3px',
                           background: '#303030'
                        }}
                        to={{
                          pathname: "/aboutUs",
                          state:{ 
                            pageTitle: "關於集團", 
                            bannerImg: banner_about, 
                            bannerAlt: "banner-about-img"
                          }
                        }} 
                      >
                        { t('home.readmore.btn') }
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='core-wrapper news-area'>
            <div className='bg-wrapper'>
              <div className='bg' style={{
                opacity: 0.2,
                backgroundImage: `url(${news_BG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundAttachment: 'fixed',
                width: '100%',
                backfaceVisibility: 'hidden'
              }}>
              </div>
            </div>
            <div style={{position: 'relative'}}>
              <div className='container-wrapper container clearfix'>
                <div className='core-item-pd-lr clearfix center-align'
                     style={{paddingBottom: '60px', position: 'relative'}}>
                  <h3 style={{
                    fontSize: '28px',
                    fontWeight: 500,
                    letterSpacing: '0px',
                    textTransform: 'none',
                    color: '#1ebef0'
                  }}>
                    { t('home.news.title') }
                  </h3>
                </div>
                <div className='column column-first column-60'>
                  <div style={{height: '377.5px'}}>
                    <div className='bg-wrapper' style={{background: 'transparent'}}>
                      <div className='bg'
                           style={{
                             opacity: 0.6,
                             backgroundSize: 'cover',
                             backgroundPosition: 'center'
                           }}>
                      </div>
                    </div>
                    <div className='column-content clearfix'>
                      <div className='core-item-pd-lr clearfix' style={{paddingBottom: '10px', position: 'relative'}}>
                        <div style={{overflow: 'hidden', position: 'relative'}}>
                          <ul>
                            {[...newsList].reverse().map((post, index) => (
                              <li key={index}>
                                <div style={{marginBottom: 0}}>
                                  <div className='blog-thumbnail media-image zoom-on-hover'>
                                    <Link to={`/news/${post.key}`}>
                                      <img src={post.image.default} width={700} height={700}
                                        alt=''
                                        style={{
                                          margin: 0,
                                          maxWidth: '100%',
                                          height: 'auto',
                                          verticalAlign: 'middle'
                                        }}
                                      />
                                    </Link>
                                  </div>
                                  <h3
                                    style={{fontSize: '16px', marginBottom: '20px', lineHeight: 1.5}}>
                                    <Link to={`/news/${post.key}`} style={{color: '#ffffff'}}>
                                      { i18n.language === 'zh-TW' && post.title_tw }
                                      { i18n.language === 'zh-CN' && post.title_cn }
                                      { i18n.language === 'en' && post.title_en }
                                    </Link>
                                  </h3>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='core-wrapper service-area'>
            <div className='bg-wrapper' style={{backgroundColor: '#eeeeee'}}></div>
            <div style={{position: 'relative'}}>
              <div className='container-wrapper clearfix'>
                <div className='column column-20 column-first'>
                  <div style={{position: 'relative', margin: '20px', padding: '0 30px'}}>
                    <div className='bg-wrapper' style={{backgroundColor: '#ffffff', borderRadius: '10px'}}></div>
                    <div className='column-content clearfix' data-aos="fade-up">
                      <div className='core-item-pd-lr center-align' style={{paddingBottom: 0, lineHeight: 0}}>
                        <div className='image-item-wrapper' style={{borderWidth: 0}}>
                          <img src={Buyippee} alt='' />
                        </div>
                      </div>
                      <div className='core-item-pd-lr core-item-pd-b center-align'>
                        <div style={{fontSize: '16px', textTransform: 'none'}}>
                          <p style={{lineHeight: 1.7, color: '#727272'}}>
                            { t('home.service.buyippee') }
                          </p>
                        </div>
                      </div>
                      <div className='core-item-pd-lr core-item-pd-b center-align'>
                        <Link 
                          className='core-button'
                          style={{
                            fontSize: '14px',
                            borderRadius: '5px',
                            background: 'linear-gradient(#74ceed, #2dbfee)',
                            padding: '15px 33px',
                            display: 'inline-block'
                          }}
                          to={{
                            pathname: "/service",
                            state:{ 
                              pageTitle: "核心業務", 
                              bannerImg: banner_service, 
                              bannerAlt: "banner-service-img"
                            }
                          }} 
                        >
                          <span className='core-content' style={{color: '#ffffff'}}>
                            { t('home.readmore.btn') }
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='column column-20'>
                  <div style={{position: 'relative', margin: '20px', padding: '0 30px'}}>
                    <div className='bg-wrapper' style={{backgroundColor: '#ffffff', borderRadius: '10px'}}></div>
                    <div className='column-content clearfix' data-aos="fade-up">
                      <div className='core-item-pd-lr center-align' style={{paddingBottom: 0, lineHeight: 0}}>
                        <div className='image-item-wrapper' style={{borderWidth: 0}}>
                          <img src={Continental} alt=''/>
                        </div>
                      </div>
                      <div className='core-item-pd-lr core-item-pd-b center-align'>
                        <div style={{fontSize: '16px', textTransform: 'none'}}>
                          <p style={{lineHeight: 1.7, color: '#727272'}}>
                            { t('home.service.continental') }
                          </p>
                        </div>
                      </div>
                      <div className='core-item-pd-lr core-item-pd-b center-align'>
                        <Link 
                          className='core-button'
                          style={{
                            fontSize: '14px',
                            borderRadius: '5px',
                            background: 'linear-gradient(#74ceed, #2dbfee)',
                            padding: '15px 33px',
                            display: 'inline-block'
                          }}
                          to={{
                            pathname: "/service",
                            state:{ 
                              pageTitle: "核心業務", 
                              bannerImg: banner_service, 
                              bannerAlt: "banner-service-img"
                            }
                          }} 
                        >
                          <span className='core-content' style={{color: '#ffffff'}}>
                            { t('home.readmore.btn') }
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='column column-20'>
                  <div style={{position: 'relative', margin: '20px', padding: '0 30px'}}>
                    <div className='bg-wrapper' style={{backgroundColor: '#ffffff', borderRadius: '10px'}}></div>
                    <div className='column-content clearfix' data-aos="fade-up">
                      <div className='core-item-pd-lr center-align' style={{paddingBottom: 0, lineHeight: 0}}>
                        <div className='image-item-wrapper' style={{borderWidth: 0}}>
                          <img src={Ids} alt=''/>
                        </div>
                      </div>
                      <div className='core-item-pd-lr core-item-pd-b center-align'>
                        <div style={{fontSize: '16px', textTransform: 'none'}}>
                          <p style={{lineHeight: 1.7, color: '#727272'}}>
                            { t('home.service.ids') }
                          </p>
                        </div>
                      </div>
                      <div className='core-item-pd-lr core-item-pd-b center-align'>
                        <Link 
                          className='core-button'
                          style={{
                            fontSize: '14px',
                            borderRadius: '5px',
                            background: 'linear-gradient(#74ceed, #2dbfee)',
                            padding: '15px 33px',
                            display: 'inline-block'
                          }}
                          to={{
                            pathname: "/service",
                            state:{ 
                              pageTitle: "核心業務", 
                              bannerImg: banner_service, 
                              bannerAlt: "banner-service-img"
                            }
                          }} 
                        >
                          <span className='core-content' style={{color: '#ffffff'}}>
                            { t('home.readmore.btn') }
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='core-wrapper partners-area' style={{padding: '45px 0px 15px 0px'}}>
            <div className='container clearfix container-wrapper'>
              <div className='title-item clearfix left-align' style={{paddingBottom: '55px'}}>
                <h3>
                  { t('home.partners.title') }
                </h3>
              </div>
              <div className='core-item-pd-lr core-item-pd-b clearfix'>
                <div className='core-flexslider'>
                  <div style={{overflow: 'hidden', position: 'relative'}}>
                    <Slider {...partner_settings}>
                      {PartnerImages()}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
}