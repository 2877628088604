import React from 'react';
import { Link, useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Layout, Row, Col, Card, Button } from 'antd';
import '../styles/blog.css';
import Banner from "../components/Banner";
import { useTranslation } from 'react-i18next';
import blogList from '../config/blogList';
import { FieldTimeOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Meta } = Card;

function Blog(props) {
  let { blogId } = useParams();
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  return(
    <>
      <MetaTags>
        <title>
          { langTW && '電商日誌 | CBES 唯高盛集團' }
          { langCN && '电商日志 | CBES 唯高盛集團' }
        </title>
      </MetaTags>
      <Banner data={ props.data } />
      <Content>
        <div className="wrap">
          <div className="blog_content">
            <Row>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <div className="blog_left">
                  <Row gutter={[0, 48]}>
                    {
                      [...blogList].reverse().map(item => (
                        <Col key={ item.key }>
                          <Card
                            className="blog_card"
                            cover={
                              <Link to={`/blog/${item.key}`}>
                                <img
                                  alt={ item.image_alt }
                                  src={ item.image.default }
                                />
                              </Link>
                            }
                          >
                            <Meta
                              title={
                                <>
                                  <div className="blog_title">
                                    <Row>
                                      <Col xs={0} sm={0} md={0} lg={3} xl={3} className="blog_title_time">
                                        <Row className="blog_title_date">
                                          { item.date }
                                        </Row>
                                        <Row className="blog_title_month">
                                          { langTW && item.month }
                                          { langCN && item.month }
                                        </Row>
                                      </Col>
                                      <Col xs={24} sm={24} md={24} lg={21} xl={21} className="blog_title_text">
                                        <div className="blog_card_date">
                                          <FieldTimeOutlined />
                                          <span>{ item.time }</span>
                                        </div>
                                        <Link to={`/blog/${item.key}`}>
                                          <h2 className="blog_card_title">
                                            { langTW && item.title_tw }
                                            { langCN && item.title_cn }
                                          </h2>
                                        </Link>
                                      </Col>
                                    </Row>
                                  </div>
                                </>
                              } 
                              description={  
                                <p className="blog_card_description">
                                  { langTW && item.description_tw }
                                  { langCN && item.description_cn }
                                </p>
                              }
                            />
                            <Button className="blog_card_btn">
                              <Link to={`/blog/${item.key}`}>
                                { langTW && '繼續閱讀' }
                                { langCN && '继续阅读' }
                              </Link>
                            </Button>
                          </Card>
                        </Col>
                      ))
                    }
                  </Row>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="blog_right">
                  <h3>近期文章</h3>
                  {
                    blogId === '8' ?
                    null :
                    <Row className="latest_blog_item" gutter={24}>
                      <Col>
                        <div className="imgWrap">
                          <img
                            alt={ blogList[7].image_alt }
                            src={ blogList[7].image.default }
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <Link to='/blog/8'>
                          <h4>
                            { langTW && blogList[7].title_tw }
                            { langCN && blogList[7].title_cn }
                          </h4>
                        </Link>
                        <FieldTimeOutlined />
                        <span>{ blogList[7].time }</span>
                      </Col>
                    </Row>
                  }
                  {
                    blogId === '7' ?
                    null : 
                    <Row className="latest_blog_item" gutter={24}>
                      <Col>
                        <div className="imgWrap">
                          <img
                            alt={ blogList[6].image_alt }
                            src={ blogList[6].image.default }
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <Link to='/blog/7'>
                          <h4>
                            { langTW && blogList[6].title_tw }
                            { langCN && blogList[6].title_cn }
                          </h4>
                        </Link>
                        <FieldTimeOutlined />
                        <span>{ blogList[6].time }</span>
                      </Col>
                    </Row>
                  }
                  {
                    blogId === '6' ?
                    null :
                    <Row className="latest_blog_item" gutter={24}>
                      <Col>
                        <div className="imgWrap">
                          <img
                            alt={ blogList[5].image_alt }
                            src={ blogList[5].image.default }
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <Link to='/blog/6'>
                          <h4>
                            { langTW && blogList[5].title_tw }
                            { langCN && blogList[5].title_cn }
                          </h4>
                        </Link>
                        <FieldTimeOutlined />
                        <span>{ blogList[5].time }</span>
                      </Col>
                    </Row>
                  }
                  {
                    blogId === '8' || blogId === '7' || blogId === '6' ?
                    <Row className="latest_blog_item" gutter={24}>
                      <Col>
                        <div className="imgWrap">
                          <img
                            alt={ blogList[4].image_alt }
                            src={ blogList[4].image.default }
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <Link to='/blog/5'>
                          <h4>
                            { langTW && blogList[7].title_tw }
                            { langCN && blogList[7].title_cn }
                          </h4>
                        </Link>
                        <FieldTimeOutlined />
                        <span>{ blogList[4].time }</span>
                      </Col>
                    </Row> :
                    null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Content>
    </>
  )
}

export default Blog;