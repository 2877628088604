module.exports = [
  {
    "key": 1,
    "title_tw": "Buyippee X Rakuten 電商代購新策略講堂",
    "title_cn": "Buyippee X Rakuten 电商代购新策略讲堂",
    "time": "2018-12-22",
    "month": "12月",
    "date": "22",
    "description_tw": 
      "馬雲曾說過：「不做電子商務，五年後你會後悔。十年後，你要是在不做電子商務的話，那麼你將無商可務。」根據eMar...",
    "description_cn": 
      "马云曾说过：「不做电子商务，五年后你会后悔。十年后，你要是在不做电子商务的话，那么你将无商可务。」根据eMar...",
    "image": require("../img/Blog_list_01.jpg"),
    "image_alt": "Blog_list_01"
  },
  {
    "key": 2,
    "title_tw": "德國運輸行業製造商Continental 發表機器物流狗",
    "title_cn": "德国运输行业制造商Continental 发表机器物流狗",
    "time": "2019-01-14",
    "month": "1月",
    "date": "14",
    "description_tw": 
      "Continental 大陸集團雖在多數人的印象是輪胎品牌，不過他們在新一代無人車技術也相當積極投入，在今年 ...",
    "description_cn": 
      "Continental 大陆集团虽在多数人的印象是轮胎品牌，不过他们在新一代无人车技术也相当积极投入，在今年 ...",
    "image": require("../img/Blog_list_03.jpg"),
    "image_alt": "Blog_list_03"
  },
  {
    "key": 3,
    "title_tw": "亞馬遜美國網站悄悄支援繁體中文 香港和台灣用戶購物配合Buyippee更方便",
    "title_cn": "亚马逊美国网站悄悄支援繁体中文 香港和台湾用户购物配合Buyippee更方便",
    "time": "2019-03-06",
    "month": "3月",
    "date": "06",
    "description_tw": 
      "對於喜歡在美國亞馬遜網站購物的朋友有福了!以往可能都需要請在美國的朋友幫你代購回香港和台灣，現在你到美國亞馬遜...",
    "description_cn": 
      "对于喜欢在美国亚马逊网站购物的朋友有福了!以往可能都需要请在美国的朋友帮你代购回香港和台湾，现在你到美国亚马逊...",
    "image": require("../img/Blog_list_04.png"),
    "image_alt": "Blog_list_04"
  },
  {
    "key": 4,
    "title_tw": "電商品牌如何尋覓”KOL”",
    "title_cn": "电商品牌如何寻觅”KOL”",
    "time": "2019-03-06",
    "month": "3月",
    "date": "06",
    "description_tw": 
      "近年各大品牌執行網路行銷操作，許多網路公司常常會協助電商品牌客戶執行 KOL 合作。但是想找KOL合作必須先了...",
    "description_cn": 
      "近年各大品牌执行网路行销操作，许多网路公司常常会协助电商品牌客户执行 KOL 合作。但是想找KOL合作必须先了...",
    "image": require("../img/Blog_list_05.jpg"),
    "image_alt": "Blog_list_05"
  },
  {
    "key": 5,
    "title_tw": "台灣跨境電商國際拓展推動計畫",
    "title_cn": "台湾跨境电商国际拓展推动计画",
    "time": "2019-03-15",
    "month": "3月",
    "date": "15",
    "description_tw": 
      "台灣經濟部商業司為協助電子商務業者跨境發展，以提升交易額成長動能、厚植產業競爭力，依據「經濟部協助產業創新活動...",
    "description_cn": 
      "台湾经济部商业司为协助电子商务业者跨境发展，以提升交易额成长动能、厚植产业竞争力，依据「经济部协助产业创新活动...",
    "image": require("../img/Blog_list_06.jpg"),
    "image_alt": "Blog_list_06"
  },
  {
    "key": 6,
    "title_tw": "Instagram推出新功能 可於應用程式內一鍵下單 促進電子商務發展",
    "title_cn": "Instagram推出新功能 可于应用程式内一键下单 促进电子商务发展",
    "time": "2019-03-21",
    "month": "3月",
    "date": "21",
    "description_tw": 
      "Instagram正開啟一個全新的收入來源，由於增加了付款相關的資訊，全球13億用戶只要點擊他們想要在購買的商...",
    "description_cn": 
      "Instagram正开启一个全新的收入来源，由于增加了付款相关的资讯，全球13亿用户只要点击他们想要在购买的商...",
    "image": require("../img/Blog_list_07.jpg"),
    "image_alt": "Blog_list_07"
  },
  {
    "key": 7,
    "title_tw": "Google姊妹公司Wing正式推出澳洲無人機外送服務/Uber將在2021年跟進!",
    "title_cn": "Google姊妹公司Wing正式推出澳洲无人机外送服务/Uber将在2021年跟进!",
    "time": "2019-04-13",
    "month": "4月",
    "date": "13",
    "description_tw": 
      "你有曾經想過用無人機送上早晨的咖啡和熱騰騰的可頌，而不是在星巴克排隊等候嗎?如果你住在澳洲北坎培拉(North...",
    "description_cn": 
      "你有曾经想过用无人机送上早晨的咖啡和热腾腾的可颂，而不是在星巴克排队等候吗?如果你住在澳洲北坎培拉(North...",
    "image": require("../img/Blog_list_08.jpg"),
    "image_alt": "Blog_list_08"
  },
  {
    "key": 8,
    "title_tw": "英國B2C電子商務銷售額將在2019年突破到2000億歐元",
    "title_cn": "英国B2C电子商务销售额将在2019年突破到2000亿欧元",
    "time": "2019-06-10",
    "month": "6月",
    "date": "10",
    "description_tw": 
      "預計今年年底英國的B2C電子商務銷售額將超過2000億歐元。在2018年英國B2C電子商務銷售額約1750億歐...",
    "description_cn": 
      "预计今年年底英国的B2C电子商务销售额将超过2000亿欧元。在2018年英国B2C电子商务销售额约1750亿欧...",
    "image": require("../img/Blog_list_11.jpg"),
    "image_alt": "Blog_list_11",
    "image_index": require("../img/Index_blog_01.jpg")
  },
]